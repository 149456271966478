import React from 'react';
import {
  Grid,
  Section,
  H1,
  Box,
  FlexCol,
  LinkPrimaryButton,
} from '../components';

import image from '../images/404.png';

import Layout from '../layout/Layout';
import { Translation } from '../layout/Translation';
import { space, fontWeights } from '../theme';
import { useIntl } from '../hooks';
import { graphql } from 'gatsby';

const NotFoundPage: React.FC<{ location: any; data: any }> = ({
  location,
  data,
}) => {
  const intl = useIntl();
  const header = data.contentfulHeader;
  const footer = data.contentfulFooter;

  return (
    <Layout
      location={location}
      header={header}
      footer={footer}
      seoTitle={intl.formatMessage({ id: '404.seoTitle' })}
    >
      <Section py={space.xl}>
        <Grid.Container>
          <Box
            mt={space.xxxxl}
            mb={space.xxxxl}
            maxWidth="800px"
            marginLeft="auto"
            marginRight="auto"
          >
            <img
              style={{ maxWidth: '100%' }}
              src={image}
              title={intl.formatMessage({ id: '404.button.title' })}
            />
          </Box>
          <Box maxWidth="600px" marginLeft="auto" marginRight="auto">
            <Box mb={space.xxl}>
              <H1 fontWeight={fontWeights.bold} textAlign="center">
                <Translation id="404.title" />
              </H1>
            </Box>
            <FlexCol alignItems="center" mb={space.xxxl}>
              <LinkPrimaryButton
                title={intl.formatMessage({ id: '404.button.text' })}
                to={intl.formatMessage({ id: '404.button.link' })}
              >
                <Translation id="404.button.text" />
              </LinkPrimaryButton>
            </FlexCol>
          </Box>
        </Grid.Container>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query NotFound($locale: String) {
    contentfulHeader(node_locale: { eq: $locale }) {
      menuItems {
        menuTitle
        menuLink
        isButton
        menuItems {
          menuTitle
          menuLink
          isButton
        }
      }
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      menuItems {
        menuTitle
        menuLink
      }
      legalMenuItems {
        menuTitle
        menuLink
      }
    }
  }
`;

export default NotFoundPage;
